import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';


export default function FormDialog(props) {
  const [sending, setSending] = React.useState(false);
  const [form, setForm] = React.useState();
  const [sentStatus, setSentStatus] = React.useState("");
  const [linksState, setLinksState] = React.useState({
    link01: false,
    link02: true,
    link03: false,
    link04: false,
    link05: false
  });

  const handleCheckboxChange = (event) => {
    setLinksState({ ...linksState, [event.target.name]: event.target.checked });
  };

  const { selectedRows, openDialog, handleClose, handleEmailSent } = props;

  const defaultSubject = "Oznámení";
  const defaultLinks = [
    'https://celostnikomunikace.cz/terapie/objednavka-3-mesicni-balicek-terapii-rodic-dite/',
    'https://celostnikomunikace.cz/terapie/objednavka-6-x-logopedicka-terapie-s-mgr-kolmanovou/',
    'https://celostnikomunikace.cz/terapie/objednavka-6x-koucink-pro-rodice-klienta-lsu/',
    'https://celostnikomunikace.cz/terapie/objednavka-3x-koucink-klub/',
    'https://celostnikomunikace.cz/terapie/objednavka-6-x-logopedicka-terapie-sourozenci-45-minut/',
  ];
  const defaultText = `Krásný den,

došlo k vyčerpání předplaceného balíčku. Níže zasíláme odkaz k platbě pro jeho obnovení:

{Odkazy}

došlo k vyčerpání předplaceného balíčku. Na Váš email byl právě zaslán požadavek pro platbu přes paypal/cash-me. Prosíme o její uhrazení.

Děkujeme a těšíme se na další spolupráci.

Martina a Helena`;

  //<a href="">objednavky</a>

  useEffect(() => {
    const sendEmail = async () => {

      const to = form["to_email"].value;
      const subject = form["subject"].value;
      const message = form["message"].value;

      let linksString = "";
      Object.keys(linksState).forEach((key, index) => {
        if (linksState[key]) {
          linksString += `<a href="${defaultLinks[index]}">${defaultLinks[index]}</a>\n`;
        }
      });

      let linesString = ""; 
      message.split('\n').forEach(line => {
        if (line && line.length > 1 && line !== '\n') linesString += `<tr><th style="padding-bottom: 20px; font-weight: 200;">${line.replace('\n', '')}</th></tr>`
      });

      console.log(linesString);

      const messageHtml = `<!DOCTYPE html>
      <html lang="cs">
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <link rel="preconnect" href="https://fonts.gstatic.com">
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Open+Sans:wght@200&family=Roboto:wght@200&display=swap" rel="stylesheet">
      <body style="padding: 5px; background-color: #24c0c5;">
        <table style="padding: 20px; background-color: #fdfdfd; font-family: 'Dosis', 'Roboto', sans-serif, 'Open Sans'; text-align: left; border-radius: 2px;width: 100%;">
          ${linesString.replace('{Odkazy}', linksString)}
        </table>
      </body>
      </html>
      `

      const data = {
        from: "\"Celostní komunikace\" <noreply@celostnikomunikace.cz>",
        subject,
        message,
        messageHtml,
        to
      }
      try {

        const devUrl = "http://localhost:5001/therapy-manager-82e60/us-central1/sendEmail";
        const prodUrl = "https://us-central1-therapy-manager-82e60.cloudfunctions.net/sendEmail";
        const response = await axios.post(prodUrl, data);

        //console.log(response);

        handleEmailSent();
        setSentStatus('Email odeslán');
      } catch (err) {
        console.log(err);
        setSentStatus('Odesílání selhalo');
      } finally {
        setSending(false);
        setForm(undefined);
      }
    }

    if (form) sendEmail();
  }, [form]);

  const handleSendEmail = (event) => {
    event.preventDefault();
    
    setSending(true);
    setForm(event.target);

    /*
    setTimeout(() => {
      
      setSending(false);
      setSentStatus('Email odeslán');
    }, 2500);
  */
  }

  const { link01, link02, link03, link04, link05 } = linksState;

  return (
    <div>
      <Dialog maxWidth='md' open={openDialog} onClose={handleClose} aria-label="form-dialog-title">
        <DialogTitle id="form-dialog-title">Poslat upozornění o vyčerpání počtu terapií/sezení</DialogTitle>
          
        <form onSubmit={handleSendEmail}>
          <DialogContent>
            { selectedRows.some(row => row.emailSent) && <Alert severity="info">Email již byl odeslán</Alert> }
            { selectedRows.map(row => (
              <TextField
                fullWidth
                margin="dense"
                id={"email" + row.key}
                label="Příjemce"
                type="email"
                name="to_email"
                defaultValue={row.email}
                key={row.key}
                />
            ))}
            <TextField
              fullWidth
              margin="dense"
              id={"subject"}
              label="Předmět"
              name="subject"
              defaultValue={defaultSubject}
              />
            <TextField
              fullWidth
              autoFocus
              id="message"
              name="message"
              label="Zpráva"
              multiline={true}
              defaultValue={defaultText}/>
            <FormControl component="fieldset" style={{ marginTop: 10 }}>
              <FormLabel component="legend">Odkazy</FormLabel>
              <FormGroup>
                <FormControlLabel 
                  control={<Checkbox checked={link01} onChange={handleCheckboxChange} name="link01"/>}
                  label={defaultLinks[0]}
                  />
                <FormControlLabel 
                  control={<Checkbox checked={link02} onChange={handleCheckboxChange} name="link02"/>}
                  label={defaultLinks[1]}
                />
                <FormControlLabel 
                  control={<Checkbox checked={link03} onChange={handleCheckboxChange} name="link03"/>}
                  label={defaultLinks[2]}
                />
                <FormControlLabel 
                  control={<Checkbox checked={link04} onChange={handleCheckboxChange} name="link04"/>}
                  label={defaultLinks[3]}
                />
                <FormControlLabel 
                  control={<Checkbox checked={link05} onChange={handleCheckboxChange} name="link05"/>}
                  label={defaultLinks[4]}
                />
              </FormGroup>

            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zrušit
            </Button>
            <Button disabled={sending} type="submit" color="primary">
               { !sending && 'Odeslat' }
               { sending && <CircularProgress size={16} /> }
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={Boolean(sentStatus)} autoHideDuration={2500} onClose={() => setSentStatus(false)}>
        <SnackbarContent message={sentStatus} />
      </Snackbar>
    </div>
  );
}
