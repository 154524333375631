import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { TextField, Button } from '@material-ui/core';
import DatePickerInput from './DatePickerInput';
import { EnhancedTableHead, EnhancedTableToolbar } from './EnhancedTableHead';
import FormDialog from './SendEmailDialog';

function createData(name = "", email = "", dates = [], deleted = false, emailSent = false) {
  return { name, email, dates, deleted, emailSent };
}

// expect dateString parameter in dd/mm/yyyy format
function convertDateStringToDateTime(dateString) {
  
  const ddmmyyyy = dateString?.split('/');

  //console.log(dateString, ddmmyyyy?.toString());

  if (Array.isArray(ddmmyyyy) && ddmmyyyy.length === 3) {
    return new Date(ddmmyyyy[2], ddmmyyyy[1] - 1, ddmmyyyy[0])?.getTime() || new Date().getTime();
  } else return new Date().getTime();
}

function AddDateForm(props) {
  const { onAddDate, clientKey, disabled } = props;

  return (
    <form onSubmit={(event) => onAddDate(event, clientKey)} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <DatePickerInput margin="dense" style={{ marginLeft: 20}}></DatePickerInput>
      <IconButton disabled={disabled} type="submit" aria-label="přidat" color="primary">
        <AddBoxIcon/>
      </IconButton>
    </form>
    
  )
}

function RemoveDateButton(props) {
  const { onRemoveDate, clientKey, disabled } = props;

  return (
    <IconButton disabled={disabled} onClick={(event) => onRemoveDate(event, clientKey)} aria-label="odebrat" color="secondary">
      <RemoveCircleIcon/>
    </IconButton>
  )
}

function InputRow(props) {
  const { onSubmitRow } = props;

  return (
    <form onSubmit={(event) => onSubmitRow(event)} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <TextField margin="normal" id={"input-name" + Math.random()} label="Jméno" style={{ marginLeft: 20}}></TextField>
      <TextField type="email" margin="normal" id={"input-email" + Math.random()} label="Email" style={{ marginLeft: 20}}></TextField>
      <DatePickerInput margin="normal" style={{ marginLeft: 20}}></DatePickerInput>
      <Button variant="contained" color="primary" margin="normal" style={{ marginLeft: 'auto', marginRight: 20 }} type="submit">Přidat</Button>
    </form>
  );
}

function formatDate(milisArray = []) {
  return milisArray.map(date => " " + new Date(date).toLocaleDateString("cs-CZ")).toString();
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ClientTable(props) {
  const { database, databaseRef, title, hidden } = props;
  const maxDates = databaseRef === "clients/adults" ? 3 : 6;

  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDialog, setOpenDialog] = React.useState(false);

  // subscribe to our database changes
  useEffect(() => {
    const listenOnChange = (database, databaseRef) => {
      const clientsRef = database.ref(databaseRef);
      clientsRef.orderByChild('deleted').equalTo(false).on('value', (snapshot) => {
        const data = snapshot.val();
  
        if (data) {
 
          const dataRows = Object.keys(data)
          .map(key => {
            const row = data[key];
            return { ...createData(row.name, row.email, row.dates, row.deleted, row.emailSent), key };
          });
  
          setRows(dataRows);
        } else {
          setRows([]);
        }
  
      });
    }

    if (database && databaseRef) listenOnChange(database, databaseRef);
  }, [database, databaseRef]);

  const handleEmailSent = (key) => {
    const updates = {};

    updates[`${databaseRef}/${key}/emailSent`] = true;

    //console.log(updates);

    database.ref().update(updates);
  }

  const handleRemoveDate = (event, key) => {
    const row = rows.find(row => row.key === key);

    const deleteDate = window.confirm(`Opravdu chcete odebrat poslední datum u klienta ${row.name}?`);

    if (deleteDate) {
      row.dates.pop();

      const updates = {};
      updates[`${databaseRef}/${key}/dates`] = row.dates;

      database.ref().update(updates);
    }
    
  }

  const handleAddDate = (event, key) => {
    event.preventDefault();
    const dateString = event?.target?.[0]?.value;

    const date = convertDateStringToDateTime(dateString);
    const row = rows.find(row => row.key === key);

    console.log(date);
    // update client by client key

    const updates = {};
    updates[`${databaseRef}/${key}/dates`] = [ ...row.dates, date ];
    
    database.ref().update(updates);
  }

  const handleDeleteRow = (event) => {
    const deleteRow = window.confirm(`Opravdu chcete smazat klienta/y?`);

    // update client with 'deleted' param set to true

    let updates = {};

    if (deleteRow) {
      rows.forEach(row => {
        if (selected.findIndex(key => row.key === key) > -1) {
          updates[`${databaseRef}/${row.key}/deleted`] = true;
        }
      });

      database.ref().update(updates);
      setSelected([]);
    }
  }

  const handleAddRow = (event) => {
    event.preventDefault();
    const name = event?.target?.[0].value;
    const email = event?.target?.[1].value;
    const dateString = event?.target?.[2]?.value;

    const date = convertDateStringToDateTime(dateString);
  
    if (name && email && date) {
      
      const key = database.ref().child(databaseRef).push().key;

      const updates = {};
      updates[`${databaseRef}/${key}`] = createData(name, email, [date], false, false);
      
      database.ref().update(updates);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, key) => {
    //console.log(key)
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (key) => selected.indexOf(key) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
      !hidden && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              title={title}
              numSelected={selected.length}
              handleDeleteRow={handleDeleteRow} 
              onOpenDialog={() => setOpenDialog(true)} />
            <TableContainer style={{ overflow: 'hidden'}}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.key);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                          style={row.dates?.length === maxDates && !isItemSelected ? { background: '#cbf3f0' } : {}}
                        >
                          <TableCell
                            padding="checkbox" 
                            onClick={(event) => handleClick(event, row.key)}>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none" style={{ width: '20em' }}>
                            {row.name}
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none" style={{ width: '20em' }}>
                            {row.email}
                          </TableCell>
                          <TableCell align="left" padding="none">{formatDate(row.dates)}</TableCell>
                          <TableCell align="right" size="small" padding="none" style={{ width: '22em' }}>
                            <AddDateForm disabled={row.dates?.length === maxDates} onAddDate={handleAddDate} clientKey={row.key}/>
                          </TableCell>
                          <TableCell align="left" size="small" padding="checkbox">
                            <RemoveDateButton disabled={!row.dates?.length} onRemoveDate={handleRemoveDate} clientKey={row.key}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>            
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={"Řádků na stranu"}
              backIconButtonText="Předchozí"
              nextIconButtonText="Další"
            />
          </Paper>
          <Paper className={classes.paper}>
            <InputRow onSubmitRow={handleAddRow} />
          </Paper>
          <FormDialog
            selectedRows={rows.filter(row => selected.includes(row.key))}
            openDialog={openDialog}
            handleClose={() => setOpenDialog(false)}
            handleEmailSent={() => handleEmailSent(selected[0])}/>
        </div>
      )
  );
  /*
  
          
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Husté řádkování"
          />
  */
}
