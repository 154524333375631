import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(Date.now());
  const { margin } = props;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div style={props.style}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginLeft: 20 }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin={margin}
            id={"date-picker-inline" + Math.random()}
            label={ margin === "dense" ? "" : "Datum terapie"}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
      </MuiPickersUtilsProvider>
    </div>
  );
}
