import React, { useEffect } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/"
import ClientTable from './components/ClientTable';
import SignIn from "./components/SignIn";
import { firebaseConfig } from "./firebaseConfig";
import { Paper, Tabs, Tab } from '@material-ui/core';


function App() {
  const [user, setUser] = React.useState("");
  const [failedSignIn, setFailedSignIn] = React.useState(false);
  const [database, setDatabase] = React.useState();
  const [value, setValue] = React.useState(0);

  // hook for firebase app initialization and subscribing to auth state 
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      setDatabase(firebase.database);

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setUser(user);
        }
      })
    }

    return () => {
      setFailedSignIn(false);
    }
  }, [])

  const handleChange = (event, newValue) => {
    if (Boolean(newValue) || newValue === 0) {
      setValue(newValue);
    }
  };


  const handleSignIn = (event) => {
    event.preventDefault();

    const email = event?.target?.[0].value;
    const password = event?.target?.[2].value;

    if (email && password) {
      
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        setFailedSignIn(true);
      });
    } else {
      setFailedSignIn(true);
      //console.log(email, password);
    }
  }
  
  const handleSignOut = (event) => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      setUser("");
    }).catch((error) => {
      // An error happened.
      console.error(error);
    });
  }

  const MainApp = (props) => {
    const { database, value } = props;
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <ClientTable hidden={ value !== 0 } database={database} databaseRef="clients/children" title="Děti Helča" />
        <ClientTable hidden={ value !== 1 } database={database} databaseRef="clients/children-m" title="Děti Martina" />
        <ClientTable hidden={ value !== 2 } database={database} databaseRef="clients/adults" title="HANDLE klienti" />
      </div>
    )
  }

  return user ? (
    <Paper square elevation={3}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="tabs"
      >
        <Tab label="Děti Helča" />
        <Tab label="Děti Martina" />
        <Tab label="Koučink" />
        
        <Tab
          style={{ marginLeft: 'auto', marginRight: 20 }}
          onClick={(event) => handleSignOut(event)}
          label="Odhlásit"
          value={null}/>
        
      </Tabs>
      <MainApp database={database} value={value} />
    </Paper>)
    : (<SignIn firebase={firebase} handleSignIn={handleSignIn} failedSignIn={failedSignIn} />);  
}

export default App;
